<template>
  <div>
    <v-app-bar class="mt-2" color="rgba(0, 0, 0, 0)" flat dense>
      <v-toolbar-title>{{ isTermMode ? '予実管理' : '売上進捗' }} </v-toolbar-title>
      <v-spacer />
      <v-sheet v-if="isTermMode" width="50%" class="d-flex justify-space-between">
        <v-select v-model="term" label="期" :items="getTerms(3)" @change="onLoaded" :full-width="false" />
        <v-sheet width="200">
          <DateTime v-model="dateStart" hideTime  @commit="onLoaded">
            <template v-slot="{ click }">
              <v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on"> {{ dateStart | dateTime }} </v-btn>
            </template>
          </DateTime>
        </v-sheet>
        <v-sheet width="200">
          <DateTime v-model="dateEnd" hideTime  @commit="onLoaded">
            <template v-slot="{ click }">
              <v-btn class="ml-2" text color="primary" dark v-bind="click.attrs" v-on="click.on"> {{ dateEnd | dateTime }} </v-btn>
            </template>
          </DateTime>
        </v-sheet>
        
        <v-spacer />
        <validation-provider v-slot="{ errors }" name="支店" rules="required">
          <v-select label="支店" item-text="name" item-value="code" :items="branches" v-model="visibledBranchs" :error-messages="errors" multiple dense />
        </validation-provider>
      </v-sheet>
      <v-sheet v-else width="200">
        <DateTime label="期間" v-model="dateStart" hideTime month @commit="onDateChanged">
          <template v-slot="{ click }">
            <v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on"> 期間: {{ dateStart | dateMonth }} </v-btn>
          </template>
        </DateTime>
      </v-sheet>
      <v-menu :close-on-content-click="false" :nudge-width="300" offset-x offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="indigo" dark v-bind="attrs" v-on="on">
            <v-icon v-text="'mdi-dots-vertical'" />
          </v-btn>
        </template>
        <v-card outlined>
          <v-card-title>
            <span>
              <v-btn :loading="sukesanEditor.loading" @click="onUpdateSukesanData" outlined>
                <v-icon v-text="'mdi-autorenew'" left /> すけさん実績更新 </v-btn>
              <v-progress-linear v-if="sukesanEditor.loading" v-model="sukesanEditor.percent" height="20">
                <template v-slot:default>
                  <span class="text-caption">{{ sukesanEditor.message }}</span>
                </template>
              </v-progress-linear>
              <div class="text-caption">最終取得日時:{{ sukesanUpdateDate | dateTime }}</div>
            </span>
          </v-card-title>
          <v-card-title v-if="arrayEqual(secretKeys, [65, 83, 85, 78, 65, 82, 79])">
            <v-spacer />
            <span>
              <v-btn :loading="sukesanEditor2.loading" @click="onUpdateSukesanData2" color="warning" outlined>
                <v-icon v-text="'mdi-autorenew'" left /> *手動すけさん売上見込み更新 </v-btn>
            </span>
          </v-card-title>
          <v-card-text>
            <!-- <v-switch v-model="showGraph" label="グラフ表示" color="indigo" hide-details dense></v-switch> -->
            <!-- <v-switch v-model="showTeam" label="チーム表示" color="indigo" hide-details dense></v-switch> -->
            <XFieldSet class="mb-4" label="ジャンプ">
              <v-chip-group v-model="inVisibledBranchs" multiple active-class="grey lighten-5" column>
                <!-- <draggable v-model="branches" draggable=".draggable" class="list" tag="div" @start="onFileSortStart" @end="onFileSortEnd"> -->
                <v-list dense>
                  <v-list-item v-for="branch in branches" class="draggable" :key="`anchor${branch.code}`">
                    <v-chip :value="branch.code" color="light-blue lighten-4" small>
                      <v-icon v-text="!inVisibledBranchs.includes(branch.code) ? 'mdi-eye' : 'mdi-eye-off'" small />
                    </v-chip>
                    <a class="text-decoration-underline" @click="scrollToAnchorPoint(`anchor${branch.code}`)">
                      {{ branch.name }}
                    </a>
                  </v-list-item>
                </v-list>
                <!-- </draggable> -->
              </v-chip-group>
            </XFieldSet>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-app-bar>
    <XLoader :loading="initializing1">
      <template v-slot:label>
        <v-progress-linear :value="progress1.value" />
        <p>{{progress1.message}}</p>
      </template>
      <template v-slot="{ loaded }">
        <div v-if="loaded">
          <v-row v-if="isTermMode">
            <v-col v-for="branch in branches.filter((a) => !inVisibledBranchs.includes(a.code))" cols="12" :key="`branch${branch.code}`">
              <v-card outlined>
                <v-card-title>
                  {{ branch.name }}
                </v-card-title>
                <v-card-text>
                  <!-- 支店のチーム表 + 当期累計 -->
                  <Viewer :initializing="initializing2" :datas="datas" :sukesanDatas="sukesanDatas" :dates="dates" :sectionType="branch.sectionType" :branchCode="branch.code" :teams="branch.teams" hideSukesan hideDetailList :dataTitles="{
                    quotasBeforeNow: false,
                    quotas: true,
                    prospectAmount2: false,
                    prospectPercent2: false,
                    amount: true,
                    percent: true,
                  }" />
                </v-card-text>
                <template v-for="team in branch.teams">
                  <v-card-text :key="`team${team.code}`">
                    <p>{{ team.name }}</p>
                    <Viewer :initializing="initializing2" :datas="datas" :sukesanDatas="sukesanDatas" :dates="dates" :sectionType="branch.sectionType" :branchCode="branch.code" :teamCode="team.code" hideSukesan hideDetailList :dataTitles="{
                      quotasBeforeNow: false,
                      quotas: true,
                      prospectAmount2: false,
                      prospectPercent2: false,
                      amount: true,
                      percent: true,
                    }" />
                  </v-card-text>
                </template>
              </v-card>
            </v-col>
          </v-row>
          <template v-else>
            <v-row>
              <v-col cols="12">
                <v-card outlined>
                  <v-card-title>全社 <v-spacer />
                  </v-card-title>
                  <v-card-text>
                    <v-tabs v-model="tableTab">
                      <v-tab> 通常 </v-tab>
                      <v-tab> 補正 </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tableTab">
                      <v-tab-item>
                        <v-simple-table dense>
                          <thead>
                            <tr class="blue lighten-4">
                              <th>&nbsp;</th>
                              <th>目標</th>
                              <th>売上見込み</th>
                              <th>達成率</th>
                              <th>売上実績</th>
                              <th>達成率</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="branch in branches" :key="`branchTab1${branch.code}`">
                              <th>{{ branch.name }}</th>
                              <td>
                                {{ analyseData(datas, dateStart, AnalyseSaleTypes.ResultTotal, branch.code, null, null, null).quotas
                                  | priceJP
                                }}
                              </td>
                              <td>
                                {{ analyseData(datas, dateStart, AnalyseSaleTypes.ResultTotal, branch.code, null, null, null).prospectAmount2
                                  | priceJP
                                }}
                              </td>
                              <td>
                                {{ analyseData(datas, dateStart, AnalyseSaleTypes.ResultTotal, branch.code, null, null, null)
                                  .prospectPercent2 | percent
                                }}%
                              </td>
                              <td>
                                {{ analyseData(datas, dateStart, AnalyseSaleTypes.ResultTotal, branch.code, null, null, null).amount
                                  | priceJP
                                }}
                              </td>
                              <td>
                                {{ analyseData(datas, dateStart, AnalyseSaleTypes.ResultTotal, branch.code, null, null, null).percent
                                  | percent
                                }}%
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr class="bottom-top">
                              <th>総合計</th>
                              <td>
                                {{ analyseData(datas, dateStart, AnalyseSaleTypes.ResultTotal, null, null, null, null).quotas | priceJP }}
                              </td>
                              <th>
                                {{ analyseData(datas, dateStart, AnalyseSaleTypes.ResultTotal, null, null, null, null).prospectAmount2
                                  | priceJP
                                }}
                              </th>
                              <th>
                                {{ analyseData(datas, dateStart, AnalyseSaleTypes.ResultTotal, null, null, null, null).prospectPercent2
                                  | percent
                                }}%
                              </th>
                              <td>
                                {{ analyseData(datas, dateStart, AnalyseSaleTypes.ResultTotal, null, null, null, null).amount | priceJP }}
                              </td>
                              <td>
                                {{ analyseData(datas, dateStart, AnalyseSaleTypes.ResultTotal, null, null, null, null).percent | percent }}%
                              </td>
                            </tr>
                          </tfoot>
                        </v-simple-table>
                      </v-tab-item>
                      <v-tab-item>
                        <v-simple-table dense>
                          <thead>
                            <tr class="blue lighten-4">
                              <th>&nbsp;</th>
                              <th>目標</th>
                              <th>売上実績</th>
                              <th>達成率</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="branch in branches" :key="`branchTab1${branch.code}`">
                              <th>{{ branch.name }}</th>
                              <td>
                                {{ analyseData(datas, dateStart, AnalyseSaleTypes.CorrectedTotal, branch.code, null, null, null).quotas
                                  | priceJP
                                }}
                              </td>
                              <td>{{ analyseData(datas, dateStart, null, branch.code, null, null, null).amount | priceJP }}</td>
                              <td>
                                {{ (analyseData(datas, dateStart, null, branch.code, null, null, null).amount /
                                  analyseData(datas, dateStart, AnalyseSaleTypes.CorrectedTotal, branch.code, null, null, null).quotas)
                                  | percent
                                }}%
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr class="bottom-top">
                              <th>総合計</th>
                              <th>
                                {{ analyseData(datas, dateStart, AnalyseSaleTypes.CorrectedTotal, null, null, null, null).quotas | priceJP
                                }}
                              </th>
                              <th>{{ analyseData(datas, dateStart, null, null, null, null, null).amount | priceJP }}</th>
                              <th>
                                {{ (analyseData(datas, dateStart, null, null, null, null, null).amount /
                                  analyseData(datas, dateStart, AnalyseSaleTypes.CorrectedTotal, null, null, null, null).quotas)
                                  | percent
                                }}%
                              </th>
                            </tr>
                          </tfoot>
                        </v-simple-table>
                      </v-tab-item>
                    </v-tabs-items>
                    <v-row class="mt-4">
                      <v-col cols="12" :md="noResponsive ? 12 : 6">
                        <v-card outlined>
                          <v-card-title>営業部</v-card-title>
                          <v-card-text>
                            <Viewer :initializing="initializing2" :datas="datas" :dates="dates" :sectionType="1 << 2" :branchCode="null" :teamCode="null" hideSukesan hideDetailList />
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col cols="12" :md="noResponsive ? 12 : 6">
                        <v-card outlined>
                          <v-card-title>旅館事業部</v-card-title>
                          <v-card-text>
                            <Viewer :initializing="initializing2" :datas="datas" :dates="dates" :sectionType="1 << 4" :branchCode="null" :teamCode="null" hideSukesan hideDetailList />
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <!-- 全社にはこれは不要 -->
                    <!-- <Viewer :initializing="initializing2" :datas="datas" :date="dateStart" :branchCode="null" :teamCode="null" /> -->
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <!-- <draggable v-model="branches" draggable=".draggable" class="row" tag="div" @start="onFileSortStart" @end="onFileSortEnd"> -->
            <v-row>
              <v-col class="draggable" v-for="branch in branches.filter((a) => !inVisibledBranchs.includes(a.code))" cols="12" :md="noResponsive ? 12 : 6" :key="`branch${branch.code}`">
                <v-card outlined>
                  <v-card-title>
                    {{ branch.name }}
                    <v-spacer />
                    <v-btn icon @click="
                      branchEditor.code = branch.code;
                    branchEditor.show = true;
                    ">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <template v-if="showGraph">
                      <v-row justify="center">
                        <v-col cols="6">
                          <RadarChart :chart-data="getDataCollection()" :options="graphOptions"></RadarChart>
                        </v-col>
                        <v-col cols="6">
                          <PieChart :chart-data="pieChartDataCollection()" :options="pieChartOptions" />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <LineChart :height="150" :chart-data="lineChartDataCollection()" :options="lineChartOptions" />
                        </v-col>
                      </v-row>
                    </template>
                    <!-- 旅館事業部は支店のサマリを表示しない -->
                    <template v-if="!isRyokan(branch.sectionType)">
                      <Viewer :initializing="initializing2" :datas="datas" :dates="dates" :sectionType="branch.sectionType" :branchCode="branch.code" :teamCode="null" hideSukesan hideDetailList @demoCommit="onDemoEdit" @leasepaymentCommit="onLeasepaymentEdit" />
                    </template>
                    <template v-if="showTeam">
                      <v-divider class="my-4" />
                      <template v-for="team in branch.teams">
                        <v-card flat :key="`team${team.code}`">
                          <v-card-title>
                            <v-chip>
                              <v-icon v-if="(team.meta.teamMarkType & TeamMarkTypes.Leader) != 0" v-text="`mdi-human-greeting-variant`" />
                              {{ team.name }}
                            </v-chip>
                            <v-spacer />
                            <ValidationObserver v-slot="observer">
                              <v-bottom-sheet persistent scrollable max-width="1000" v-model="team.show" inset>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                  </v-btn>
                                </template>
                                <TeamEditor :show="team.show" :observer="observer" :teamCode="team.code">
                                  <template v-slot:close>
                                    <v-btn text color="warning" @click="team.show = false">閉じる</v-btn>
                                  </template>
                                </TeamEditor>
                              </v-bottom-sheet>
                            </ValidationObserver>
                          </v-card-title>
                          <v-card-text>
                            <Viewer :initializing="initializing2" :datas="datas" :sukesanDatas="sukesanDatas" :dates="dates" :sectionType="branch.sectionType" :teamMarkType="team.meta.teamMarkType" :branchCode="branch.code" :teamCode="team.code" :hideSukesan="(team.meta.teamMarkType & TeamMarkTypes.Leader) != 0" />
                          </v-card-text>
                        </v-card>
                      </template>
                    </template>
                  </v-card-text>
                </v-card>
                <!-- <Branch :branchCode="branch.code" :date="quotaDate" :showGraph="showGraph" :showTeam="showTeam">
              <template v-slot:title>
                <div :id="`anchor${branch.code}`">{{ branch.name }}</div>
              </template>
            </Branch> -->
              </v-col>
            </v-row>
            <!-- </draggable> -->
          </template>
        </div>
      </template>
    </XLoader>
    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable max-width="1000" v-model="branchEditor.show" inset>
        <BranchEditor :show="branchEditor.show" :observer="observer" :branchCode="branchEditor.code" @commit="branchEditor.show = false" @error="branchEditor.show = false" @cancel="branchEditor.show = false">
          <template v-slot:close>
            <v-btn text color="warning" @click="branchEditor.show = false">閉じる</v-btn>
          </template>
        </BranchEditor>
      </v-bottom-sheet>
    </ValidationObserver>
  </div>
</template>


<script>
import BranchEditor from '@/components/branchs/Edit';
import TeamEditor from '@/components/teams/Edit';

import Viewer from './Viewer';

import { AnalyseSaleTypes } from './Viewer';

import { arrayEqual } from '@/util/valid';

import { TeamMarkTypes } from '@/types';

export default {
  components: {
    BranchEditor,
    TeamEditor,
    Viewer,
  },
  computed: {
    viewMode() {
      return this.$route.query.viewMode;
    },
    isTermMode() {
      return this.viewMode == 'term';
    },

    TeamMarkTypes() {
      return TeamMarkTypes;
    },
    AnalyseSaleTypes() {
      return AnalyseSaleTypes;
    },

    arrayEqual() {
      return arrayEqual;
    },

    showGraph: {
      get() {
        return this.$store.getters.view.analyse.showGraph;
      },
      set(showGraph) {
        this.$store.commit('setViewAnalyseShowGraph', showGraph);
      },
    },

    showTeam: {
      get() {
        return this.$store.getters.view.analyse.showTeam;
      },
      set(showTeam) {
        this.$store.commit('setViewAnalyseShowTeam', showTeam);
      },
    },

    branchOrder: {
      get() {
        return this.$store.getters.view.analyse.branchOrder;
      },
      set(branchOrder) {
        this.$store.commit('setViewAnalyseBranchOrder', branchOrder);
      },
    },

    inVisibledBranchs: {
      get() {
        return this.$store.getters.view.analyse.inVisibledBranchs;
      },
      set(inVisibledBranchs) {
        this.$store.commit('setViewAnalyseInVisibledBranchs', inVisibledBranchs);
      },
    },

    visibledBranchs: {
      get() {
        return this.branches.map((a) => a.code).filter((a) => !this.inVisibledBranchs.includes(a));
      },
      set(inVisibledBranchs) {
        this.$store.commit(
          'setViewAnalyseInVisibledBranchs',
          this.branches.map((a) => a.code).filter((a) => !inVisibledBranchs.includes(a))
        );
      },
    },

    dates: {
      get() {
        return [this.dateStart, this.dateEnd];
      },
      set(newValue) {
        let val = newValue;
        if (!Array.isArray(val)) {
          let date = new Date();
          let dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
          let dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
          val = [dateStart, dateEnd];
        }
        this.dateStart = val[0];
        this.dateEnd = val[1];
      },
    },

    term: {
      get() {
        if (this.dateStart == null) return null;
        return this.$options.filters.libertyTerm(this.dateStart);
      },
      set(newValue) {
        this.dateStart = this.$options.filters.libertyTermToFirstDate(newValue);
        this.dateEnd = this.$options.filters.libertyTermToEndDate(newValue);
      },
    },

    monthSpan() {
      if (this.dates.length == 0) return 0;
      if (this.dates.length == 1) return 1;
      //
      let months = Math.abs(this.$moment(this.dates[0]).diff(this.$moment(this.dates[1]), 'months'));

      // ２期間中なので開始月分を含ませる
      months += 1;

      return months;
    },

    // dates() {
    //   let d = new Date(new Date().getFullYear(), new Date().getMonth(), 1);

    //   // return [{text:m.format("YYYY-MM-DD"),value:m.toString("yyyyMMdd")}]
    //   // 当月＋前後２ヶ月
    //   return [...Array(5).keys()].map((a) => {
    //     let m = this.$moment(d).add(a - 2, 'months');
    //     return {
    //       text: m.format('YYYY-MM'),
    //       value: m.format('YYYY-MM-DD'),
    //     };
    //   });
    // },
    /** */
    barChartOptions() {
      let options = {
        title: {
          display: true,
          text: '売上比較',
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      };
      return options;
    },

    // 現在
    now() {
      return new Date();
    },

    noResponsive() {
      if (this.isTermMode) return true;
      return false;
    },
  },

  data: () => ({
    initializing1: true,
    initializing2: true,

    progress1:{
      value:0,
      message:""
    },

    dateStart: null,
    dateEnd: null,

    dragging: false,

    // branchDatas: [],
    branches: [],
    datas: [],

    // すけさん売上見込み
    sukesanDatas: [],

    sukesanEditor: {
      loading: false,
      message: null,
      datas: [],
    },

    sukesanEditor2: {
      loading: false,
      datas: [],
    },
    branchEditor: {
      show: false,
      branchCode: null,
    },

    tableTab: 0,

    sukesanUpdateDate: null,

    // すけさん売上見込み取得ボタン表示用の隠しキー入力
    secretKeys: [],
  }),

  methods: {
    upDateBranches() {
      // return this.branches.slice().sort((a, b) => (a.dispOrder > b.dispOrder ? -1 : 1));
      // Cookieの設定順で並び替えを行う(FXIME 要リファクタリング)
      let branches = this.branches;
      let datas = [];
      let branchOrder = this.branchOrder;
      if (branchOrder == null) branchOrder = [];
      // 設定済みのデータを探し見つかれば順に入れていく
      for (let i = 0; i < branchOrder.length; i++) {
        let code = branchOrder[i];
        let res = branches.find((a) => a.code == code);
        if (res != null) datas.push(res);
      }
      // 見つからないデータを探しなければ順に入れていく
      for (let i = 0; i < branches.length; i++) {
        let branch = branches[i];
        let res = datas.find((a) => a.code == branch.code);
        if (res == null) datas.push(branch);
      }
      this.branches = datas;
    },

    async onLoaded() {
      this.initializing1 = true;
      this.initializing2 = true;

      this.setProgress1("データ取得開始",0)

      // 初期表示時は当月月初から今月末を指定する
      if (this.dateStart == null) {
        const date = new Date();
        this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
        this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      }

      //
      this.get('branches')
        .then((success) => {
          let data = success.data;

          // ダミーはのぞく
          let branches = data
            .filter((a) => !a.isDummy)
            .map((a) => ({
              show: false,
              code: a.code,
              color: a.color,
              name: a.name,
              dispOrder: a.dispOrder,
              sectionType: a.sectionType,

              teams: a.teams
                .filter((a) => !a.isDummy)
                .map((b) => ({
                  show: false,
                  code: b.code,
                  name: b.name,
                  meta: b.meta,
                })),
            }));

          //
          this.branches = branches;
          // FIXME:マスタの並び替えを使用することにしたため、本機能は使用しないクッキー状態を使用し並び順等更新
          // this.upDateBranches();

          // this.initializing1 = false;
        })
        .catch((err) => {
          console.log('err2', err);
        });

      //
      let dateStart = this.$moment(this.dateStart).format();
      let dateEnd = this.$moment(this.dateEnd).format();

      // 月で分割する(API側が月単位で展開されるので、日で展開してしまうと月をまたぐ場合予算が二つ以上取得されデータ構造がおかしくなる)
      const months = this.$moment(this.dateEnd).diff(this.$moment(this.dateStart), 'month') + 1; // 自身の月を含むので
      console.log({ months });
      if (Math.abs(months) > 1) {
        //
        let isAfter = months >= 0;
        let dateS = isAfter ? this.$moment(this.dateStart) : this.$moment(this.dateEnd);
        // let dateE = isAfter ? this.$moment(this.dateEnd) : this.$moment(this.dateStart);

        // thisを使って追加するとリアクティブで超遅いのでasyncでひとまとめにする
        let datas = [];
        for (let i = 0; i < Math.abs(months); i++) {
          console.log('month', i);

          let s = this.$moment(dateS).add(i, 'month').startOf('month'); // 月初
          let e = s.clone().endOf('month'); // 月末日

          console.log({
            s: s.format(),
            e: e.format(),
          });



          // FIXME とりあえずの負荷軽減対策
          for (let j = 0; j < 50; j++) {
            let success = await this.get('analyse/datas', { dateStart: s.format(), dateEnd: e.format(), page: j, take: 25 });
            if (success.data.length == 0) break;

            datas = datas.concat(success.data);
            console.log(`month:${i} [${j}] datas length`, success.data.length);
          }
          /*

          let success = await this.get('analyse/datas', { dateStart: s.format(), dateEnd: e.format() });
          datas = datas.concat(success.data);
          
          this.initializing2 = false;
          */

          //
          this.setProgress1(`ロード中 ${i+1} / ${Math.abs(months)}`,((i+1)/Math.abs(months))*100)
          //
          s = e.clone();
        }

        {
          this.setProgress1("画面作成中(環境によって時間がかかる場合があります",100)
          // 重いレンダリングまえに上記が表示できるよう処理時間を空ける
          const sleep = (millisecond) => new Promise(resolve => setTimeout(resolve, millisecond ))
          await sleep(500);
        }
        //
        this.datas = datas;
      } else {

        let datas = []
        // FIXME とりあえずの負荷軽減対策
        for (let i = 0; i < 50; i++) {
          let success = await this.get('analyse/datas', { dateStart: dateStart, dateEnd: dateEnd, page: i, take: 25 });
          if (success.data.length == 0) break;
          datas = datas.concat(success.data);

             //
             this.setProgress1(`ロード中 `,((i+1)/50)*100)
       
        }

        {
          this.setProgress1("画面作成中(環境によって時間がかかる場合があります",100)
          // 重いレンダリングまえに上記が表示できるよう処理時間を空ける
          const sleep = (millisecond) => new Promise(resolve => setTimeout(resolve, millisecond ))
          await sleep(500);
        }

        this.datas = datas;

        // this.initializing2 = false;


        // this.get('analyse/datas', { dateStart: dateStart, dateEnd: dateEnd })
        //   .then((success) => {
        //     let data = success.data;
        //     //
        //     this.datas = data;

        //     this.initializing2 = false;
        //   })
        //   .catch((err) => {
        //     console.log('err1', err);
        //   });
      }

      this.initializing1 = false;


      /*
      this.get('analyse/datas', { dateStart: dateStart, dateEnd: dateEnd })
        .then((success) => {
          let data = success.data;
          //
          this.datas = data;

          this.initializing2 = false;
        })
        .catch((err) => {
          console.log('err1', err);
        });
      */

      // すけさん情報取得日を取得
      this.get('setting/meta', {}).then((success) => {
        let data = success.data;
        this.sukesanUpdateDate = this.$moment(data.sukesanUpdateDate).format();
      });

      //
      {
        let year = this.$moment(dateStart).year();
        let month = this.$moment(dateStart).month() + 1;

        this.get('RSViewer', { year: year, month: month })
          .then((success) => {
            let data = success.data;
            //
            this.sukesanDatas = data;
          })
          .catch((err) => {
            console.log('err1', err);
          });
      }
    },

    async onUpdateSukesanData() {
      //
      this.sukesanEditor.loading = true;

      //
      let now = this.$moment(this.now).format();
      let dateStart = this.$moment(this.dateStart).format();
      let dateEnd = this.$moment(this.dateEnd).format();

      try {
        // FIXME とりあえずの負荷軽減対策
        let datas = [];
        let i = 0;
        for (let branch of this.branches) {
          //
          let branchCode = branch.code;
          let branchName = branch.name;

          console.log({ branchCode });

          // リアクティブ
          this.$set(this.sukesanEditor, 'percent', ((i + 1) / this.branches.length) * 100);
          this.$set(this.sukesanEditor, 'message', `処理中: ${branchName}`);

          let success = await this.put('analyse/sukesan/datas', {
            now: now,
            dateStart: dateStart,
            dateEnd: dateEnd,
            branchCodes: [branchCode],
          });
          let data = success.data;
          //
          console.log({ data });

          datas = datas.concat(data);
          //
          i++;
        }

        console.log({ datas });

        this.sukesanEditor.datas = datas;

        //
        let message = `すけさん売上を${datas.length}件更新しました。`;
        if (datas.length == 0) message = `更新はありませんでした。`;

        this.showSuccess(message);

        // リロードする
        this.onLoaded();
      } catch (err) {
        console.log('err1', err);
      } finally {
        this.sukesanEditor.loading = false;
      }
    },

    async onUpdateSukesanData2() {
      //
      if (!confirm('本機能はテスト環境用です\n本番環境では使用しないでください')) return;
      //
      this.sukesanEditor2.loading = true;

      //
      const success = await this.post('RSViewer');

      let data = success.data;
      //
      let now = data.now;
      let year = data.year;
      let month = data.month;
      let weekInMonth = data.weekInMonth;
      let months = data.months;
      let start = data.start;
      let end = data.end;

      //
      let message = `${this.$options.filters.dateTime(now)}時点の`;
      message += `${year}/${month} 第${weekInMonth}週の${months + 1}ヶ月分のデータを更新しました。`; // +1は当月分
      message += ` 取得期間:${this.$options.filters.date(start)} - ${this.$options.filters.date(end)}`;

      this.showToast({ message: message, duration: 30000 });
      // リロードする
      await this.onLoaded();

      this.sukesanEditor2.loading = false;
    },

    async onDateChanged() {
      // 月指定は指定月の一日～月末を指定
      let date = this.$moment(this.dateStart).toDate();
      let dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.dateEnd = dateEnd;

      // リロードする
      await this.onLoaded();
    },

    scrollToAnchorPoint(val) {
      // const el = this.$refs[val];
      const el = document.getElementById(val);
      // chromeだと効かないが他はいける
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    },

    onFileSortStart() {
      this.dragging = true;
    },
    onFileSortEnd() {
      this.dragging = false;

      // 設定後の並び順
      let codes = this.branches.map((a) => a.code);

      // Cookieに保存する
      this.branchOrder = codes;
    },

    async onDemoEdit() {
      // リロードする
      await this.onLoaded();
    },
    async onLeasepaymentEdit() {
      // リロードする
      await this.onLoaded();
    },

    isRyokan(sectionType) {
      // FIXME 旅館事業部用ハードコーディング中
      return sectionType == 1 << 4;
    },

    //
    onKeyDown(event) {
      let key = event.which;
      if (key == 27) this.secretKeys = [];
      else this.secretKeys.push(key);
    },

    setProgress1(message,value){
      
        this.progress1.value=value;
        this.progress1.message=message;

    }
  },
  watch: {},
  async mounted() {
    // 予実モードの場合は期の開始日と終了日を指定
    if (this.isTermMode) {
      const date = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      const term = this.$options.filters.libertyTerm(date);
      this.dateStart = this.$options.filters.libertyTermToFirstDate(term);
      this.dateEnd = this.$options.filters.libertyTermToEndDate(term);
    }

    await this.onLoaded();
    document.addEventListener('keydown', this.onKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown);
  },
};
</script>